/**
 * Direttiva che verifica quando il caricamento di un elemento nel DOM. Quando quest'ultimo è costruito, la direttiva si occupa di avviare una funzione passata come attributo
 */

export interface IOnElementReadyDirectiveAttributes extends ng.IAttributes {
    onElementReady: any;
}
angular.module('app').directive('onElementReady', ($parse) => {
   return {
       restrict: 'A',
       link: ($scope: ng.IScope, element: JQuery, attrs: IOnElementReadyDirectiveAttributes) => {    
          element.ready(() =>{
            $scope.$apply(() =>{
                let func = $parse(attrs.onElementReady);
                func($scope);
            })
          })
       }
    }
});